<template>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	name: "Redirect",
	methods: {
		...mapActions(['set_active_item'])
	},
	created(){
		this.set_active_item(1);
		this.$router.push({name:'Dashboard'})
	}
};
</script>

<style scoped>
</style>
